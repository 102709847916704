/* свойства модального окна по умолчанию */
.modal {
  position: fixed;
  /* фиксированное положение */
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: rgba(0, 0, 0, 0.5);
  /* цвет фона */
  z-index: 1050;
  opacity: 0;
  /* по умолчанию модальное окно прозрачно */
  -webkit-transition: opacity 400ms ease-in;
  -moz-transition: opacity 400ms ease-in;
  transition: opacity 400ms ease-in;
  /* анимация перехода */
  pointer-events: none;
  transition: 0.25s;
  /* элемент невидим для событий мыши */
}

/* при отображении модального окно */
.show {
  opacity: 1;
  pointer-events: auto;
  overflow-y: auto;
}
// .modal:target {
//   opacity: 1;
//   pointer-events: auto;
//   overflow-y: auto;
// }

/* ширина модального окна и его отступы от экрана */
.modal-dialog {
  position: relative;
  width: auto;
  margin: 125px auto;
}

@media (max-width: 1250px) {
  .modal-dialog {
    max-width: 85% !important;
  }
}
@media (max-width: 350px) {
  .modal-dialog {
    width: 280px;
  }
}
@media (max-width: 500px) {
  // .place_container {
  //   margin-top: 50px !important;
  // }
  .denied {
    font-size: 24px !important;
    margin-top: 50px !important;
    line-height: 1.2 !important;
  }

  .modal-dialog {
    max-width: 93% !important;
  }
  .modal-body {
    width: 300px !important;
    margin: 30px auto !important;
    span {
      font-size: 26px !important;
    }
  }
  .place_container {
    .heading {
      font-size: 16px !important;
    }
    .address {
      font-size: 14px !important;
    }
    .distance {
      font-size: 14px !important;
    }
  }
}

@media (min-width: 576px) {
  .modal-dialog {
    max-width: 65%;
    margin: 210px auto;
  }
}

/* свойства для блока, содержащего контент модального окна */
.modal-content {
  position: relative;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  background-color: #fff;

  -webkit-background-clip: padding-box;
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.2);
  border-radius: 0.3rem;
  outline: 0;
  height: 780px;
  background-color: #002c90;
}

@media (min-width: 768px) {
  .modal-content {
    -webkit-box-shadow: 0 5px 15px rgba(0, 0, 0, 0.5);
    box-shadow: 0 5px 15px rgba(0, 0, 0, 0.5);
  }
}
@media (max-width: 550px) {
  .modal-header {
    padding: 10px;
  }
  .close {
    font-size: 24px;
  }
  .modal-dialog {
    width: 93%;
  }
}
@media (max-width: 550px) and (min-width: 500px) {
  .modal-body {
    width: 350px !important;
  }
  .modal-content {
    min-height: 650px;
  }
}
@media (max-width: 450px) {
  .modal-content {
    height: 650px;
  }
}
@media (max-width: 770px) and (min-width: 550px) {
  .modal-body {
    width: 400px !important;
  }
}
/* свойства для заголовка модального окна */
.modal-header {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
  -ms-flex-pack: justify;
  justify-content: space-between;
  padding: 35px;
}

.modal-title {
  margin-top: 0;
  margin-bottom: 0;
  line-height: 1.5;
  font-size: 1.25rem;
  font-weight: 500;
  display: flex;
  justify-content: center;
}

/* свойства для кнопки "Закрыть" */
.close {
  float: right;
  font-family: sans-serif;
  font-size: 32px;
  font-weight: 400;
  line-height: 1;
  color: #fff;
  // text-shadow: 0 1px 0 #fff;
  opacity: 0.7;
  text-decoration: none;
  transition: 0.1s;
  margin-bottom: 58px;
}

/* свойства для кнопки "Закрыть" при нахождении её в фокусе или наведении */
.close:focus,
.close:hover {
  color: #fff;
  text-decoration: none;
  cursor: pointer;
  opacity: 1;
}

/* свойства для блока, содержащего основное содержимое окна */
.modal-body {
  overflow: hidden;
  width: 430px;
  margin: 50px auto;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
  span {
    color: #fff;
    font-size: 32px;
    font-weight: 600;
    letter-spacing: 1.1px;
    font-stretch: normal;
  }
}
.one_station {
  justify-content: start !important;
  .place_container {
    margin-top: 120px;
  }
  p {
    margin-top: 120px;
  }
}

.place_container {
  cursor: pointer;
  transition: 0.2s;
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: #fff;
  .left_container {
    max-width: 80%;
  }
  p {
    transition: 0.2s;
    margin: 10px 0;
    letter-spacing: 1.1px;
  }
  .heading {
    transition: 0.2s;
    font-weight: 600;
    font-size: 20px;
    opacity: 0.55;
  }
  .address {
    transition: 0.2s;
    font-size: 18px;
    font-weight: 300;
    opacity: 0.35;
  }
  .distance {
    transition: 0.2s;
    font-size: 16px;
    font-weight: 300;
    opacity: 0.7;
  }
  &:hover {
    p {
      opacity: 1;
    }
    .arrow {
      opacity: 1;
    }
  }
}

.arrow {
  border: solid white;
  border-width: 0 2px 2px 0;
  display: inline-block;
  padding: 3px;
  height: 0px;
  transform: rotate(-45deg);
  margin-right: 2px;
  opacity: 0.7;
}
.denied {
  margin-top: 40px;
  font-size: 28px;
  color: #fff;
  margin-bottom: 100px;
  text-align: center;
  line-height: 1.4;
}
