@import "../../../styles/variables/colors";
@import "../../../styles/mixins/textStyle.mixin";

.info-wrapper {
  display: flex;
}

.info-wrapper-left {
  background: #fff;
  width: 50%;
}

.info-wrapper-right {
  width: 50%;
}

.one-file {
  padding-right: 3rem;
}

.one-file {
  display: flex;
  align-items: center;
  margin-top: 50px;
  cursor: pointer;

  span {
    padding-left: 30px;
    @include textStyle(white, 1.8rem, 300, normal, normal);
    flex: 1 1;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
  }

  svg {
    width: 40px;
  }

  path {
    fill: white;
  }
}

// ADVICE & USAGE
.advice-wrapper {
  // width: 600px;
  padding: 100px 150px;
  transition: 0.4s ease;
}

.advice-text {
  opacity: 0.65;
  @include textStyle($grey, 1.8rem, 300, normal, normal);
  margin-top: 30px;
  margin-bottom: 55px;
  line-height: 26px;
}

.related-title {
  margin: 0;
  @include textStyle($grey, 3.8rem, 700, normal, normal);
}

.advice-item {
  @include textStyle($grey, 1.8rem, 300, normal, normal);
  margin-bottom: 25px;
  display: flex;
  align-items: flex-start;
  svg {
    width: 25px;
    height: 25px;
  }
  span {
    &:nth-child(1) {
      width: 20px;
      height: 20px;
      // background: url(./../../static/images/product-feature-icon.png);
      display: block;
      margin-right: 20px;
      background-size: contain;
      background-repeat: no-repeat;
    }
    &:nth-child(2) {
      opacity: 0.65;
      width: calc(100% - 45px);
    }
  }
}

.advice-image-wrapper {
  margin-bottom: 100px;
  display: flex;
  justify-content: center;
}

.advice-image {
  width: 100%;
  height: 100%;
  max-height: 40rem;
  object-fit: contain;
  object-position: center;
}

/*DESCRIPTION OF PRODUCT*/
.description-wrapper {
  background: $blue;
  padding: 100px 125px;
  height: 100%;
  h2 {
    color: #fff;
  }
}

.description-text {
  opacity: 0.65;
  @include textStyle(#fff, 1.8rem, 300, normal, normal);
  margin: 80px 0;
  line-height: 26px;

  p {
    margin-bottom: 30px;
  }
}

.description-links {
  @include textStyle(#fff, 1.8rem, normal, normal);
  letter-spacing: 1.1px;
}

.description-link {
  i {
    border-color: #fff !important;
    margin: 7px 0px 0px 60px !important;
  }
}

.info {
  @include textStyle(#fff, 1.8rem, normal, normal);
}

/*BENEFITS OF PRODUCT*/
.benefits-wrapper {
  background: $darkgray_background;
  padding: 85px 125px;

  > h2 {
    color: $grey;
  }
}

.benefits-text {
  opacity: 0.65;
  @include textStyle($grey, 1.8rem, 300, normal, normal);
  margin: 50px 0;
  line-height: 26px;

  p {
    margin-bottom: 30px;
  }
}

.benefits-item {
  @include textStyle($grey, 1.8rem, 300, normal, normal);
  margin-bottom: 25px;
  display: flex;
  align-items: flex-start;
  span {
    &:nth-child(1) {
      width: 35px;
      height: 35px;
      // background: url(./../../static/images/product-benefits-icon.png);
      display: block;
      margin-right: 40px;
      background-size: contain;
      background-repeat: no-repeat;
    }
    &:nth-child(2) {
      opacity: 0.65;
      width: calc(100% - 45px);
    }
  }
}

.benefit-image-wrapper {
  width: 5rem;
  height: 5rem;
  margin-right: 5rem;
}

.benefit-image {
  width: 100%;
  height: 100%;
  object-fit: contain;
  object-position: center;
}

.related-products {
  margin-top: 5rem;
  text-align: center;
}

.products-container {
  margin-top: 8rem;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;

  > div {
    max-width: 25rem;
    margin: 1.5rem;
  }
}

@media screen and (max-width: 1700px) {
  .advice-wrapper {
    padding: 100px 130px;
  }

  .advice-wrapper {
    padding: 50px 75px;
  }

  .description-wrapper {
    padding: 50px 75px;
  }

  .benefits-wrapper {
    padding: 50px 75px;
  }

  .description-text {
    margin: 45px 0;
  }

  .benefits-text {
    margin: 40px 0;
  }

  .advice-image-wrapper {
    margin-bottom: 50px;
  }
}

@media screen and (max-width: 1366px) {
  h2 {
    font-size: 2.6rem;
  }

  .advice-wrapper {
    padding: 30px 35px;
  }

  .description-wrapper {
    padding: 30px 35px;
  }

  .benefits-wrapper {
    padding: 30px 35px;
  }

  .description-text {
    margin: 30px 0;
  }

  .benefits-text {
    margin: 20px 0;
  }

  .benefits-item {
    margin-bottom: 15px;
  }

  .description-link {
    margin-bottom: 15px;
  }
}

@media screen and (max-width: 1024px) {
  .info-wrapper-left {
    width: 100%;
  }

  .info-wrapper-right {
    width: 100%;
  }

  .info-wrapper {
    flex-direction: column;
  }

  .benefits-wrapper {
    padding: 30px 20px;
  }

  .related-products {
    padding: 30px 20px;
    margin-top: 1rem;
  }

  .advice-wrapper {
    width: initial;
    padding: 30px 20px;
  }

  .description-wrapper {
    padding: 30px 20px;
  }

  .advice-text {
    margin-bottom: 25px;
  }

  .advice-image-wrapper {
    margin-bottom: 30px;
  }

  h2 {
    font-size: 3.2rem;
  }

  .info-wrapper {
    margin-bottom: 0;
  }

  .related-products h2 {
    font-size: 1.8rem;
  }
}

@media screen and (max-width: 700px) {
  .benefit-image-wrapper {
    margin-right: 3rem;
  }
}
