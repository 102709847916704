@import "../../../styles/variables/colors";
@import "../../../styles/mixins/textStyle.mixin";

h2 {
  margin: 0;
  @include textStyle($grey_text, 3.8rem, 700, normal, normal);
  line-height: 5rem;
}

.title {
  display: flex;
  justify-content: space-between;
}

.termsTitle {
  @include textStyle($grey_text, 2.2rem, bold);
  margin-bottom: 3rem;
}

.title-text {
  color: $grey_text;
}

.info {
  display: flex;
  justify-content: space-between;
}

.share {
  // background-image: url("../../../static/images/share.png");
  min-width: 26px;
  min-height: 29px;
  transition: 0.4s ease;
  background-repeat: no-repeat;
  margin-left: 30px;
}

.text {
  width: 50%;
  padding: 0 7%;
  margin: 10rem 0;
}

.highInfo {
  @include textStyle($grey_text, 1.8rem, 300, normal, normal);
  line-height: 3rem;
  box-sizing: border-box;
}

.date {
  opacity: 0.65;
  margin: 4rem 0;
}

.text-main {
  @include textStyle($lightgray_text, 1.8rem, 300, normal, normal);
  word-break: break-all;

  p {
    margin-bottom: 3rem;
  }
}

.media {
  width: 50%;
}

.img {
  width: 100%;

  > img {
    width: 100%;
  }
}

.carousel-section {
  width: 100%;
  padding: 10rem 0;
  background-color: $darkgray_background;
}

.carousel-title {
  text-align: center;
  width: 100%;
  margin-bottom: 7rem;
}

.sections {
  > div {
    width: 100% !important;
  }
}

@media screen and (max-width: 1366px) {
  h2 {
    margin: 0;
    @include textStyle($grey_text, 2.8rem, 700, normal, normal);
    line-height: 3.5rem;
  }

  .date {
    margin: 2rem 0;
  }
}

@media screen and (max-width: 1024px) {
  .info {
    flex-direction: column;
  }

  .media {
    width: 100%;
  }

  .text {
    width: 100% !important;
  }
}

@media screen and (max-width: 600px) {
  h2 {
    font-size: 2.5rem;
    line-height: 3rem;
  }
}

.list ul {
  list-style-type: circle;
  display: unset;
  li {
    display: list-item;
  }
}
