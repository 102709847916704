@import "../../styles/variables/colors";
@import "../../styles/mixins/textStyle.mixin";
@import "../../styles/mixins/objectFit.mixin.scss";

.wrapper {
  position: relative;
  height: 45rem;
}
.carousel-wrapper {
  height: 100vh !important;
}

.background {
  @include object-fit(cover);
  width: 100%;
  height: 100%;
  position: relative;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  object-fit: cover;
  object-position: center;
  z-index: -1;
  transition: all 0.2s ease-in-out;
  opacity: 0;
}

.wrapperImg {
  display: flex;
  flex-direction: column;
  position: relative;
  height: 100%;
}

.faded {
  opacity: 1;
}

.wrapper-with-under-title {
  padding-top: 35vh;
  background-size: cover;
}

.title {
  @include textStyle(#fff, 8rem, 700, normal, normal);
  font-family: "Univers LT Std";
  font-weight: 700;
  font-stretch: normal;
  font-style: normal;
  color: #fff;
  text-shadow: 1px 1px 2px #5d5d5d;
  margin: 0;
  padding: 0 0 0 10%;
  box-sizing: border-box;
  top: 45%;
  transform: translateY(-50%);
  background: none !important;
  position: absolute;
}
.subtitle_wrapper {
  // @include textStyle(#fff, 3rem, 300, normal, normal);
  transition: 0.25s ease;
  font-family: "Univers LT Std";
  font-weight: 400;
  letter-spacing: 1.1px;
  font-stretch: normal;
  font-style: normal;
  color: #fff;
  text-shadow: 1px 1px 2px #5d5d5d;
  margin: 0;
  padding: 0 0 0 7%;
  box-sizing: border-box;
  top: 55%;
  // top: 60%;
  transform: translateY(-50%);
  background: none !important;
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 20px;
  font-size: 3rem;
}
.subtitle {
  margin-right: 30px;
}
.link {
  justify-content: space-between;
}
.arrow {
  border: solid white;
  border-width: 0 2px 2px 0;
  display: inline-block;
  padding: 3px;
  height: 0px;
  transform: rotate(-45deg);
}
.under-title {
  padding: 5% 7%;
  background-color: #f1f2f2;
  color: #5d5d5d;
  max-width: 624px;
  box-sizing: border-box;
  font-size: 2.2rem;
  font-weight: 300;
  font-family: "Univers LT Std";
  span {
    opacity: 0.65;
    max-width: 390px;
    display: block;
    line-height: 1.36;
    margin: 0 auto;
  }
  @media screen and (max-width: 1024px) {
    padding: 12% 7%;
    span {
      max-width: 48rem;
    }
    .subtitle {
      font-size: 20px !important;
    }
  }
}

@media screen and (max-width: 1450px) {
  .title {
    font-size: 6.5rem;
  }
}

@media screen and (max-width: 1024px) {
  .title {
    padding: 0 0 0 10rem;
    font-size: 5.5rem;
    top: 45% !important;
  }
  .subtitle_wrapper {
    top: 55% !important;
    font-size: 2rem !important;
  }
}

@media screen and (max-width: 768px) {
  .title {
    padding-left: 7%;
  }
}

@media screen and (max-width: 640px) {
  .title {
    top: 30% !important;
    font-size: 3.5rem !important;
    padding-left: 7%;
  }

  .subtitle_wrapper {
    top: 38% !important;
    font-size: 1.7rem !important;
  }

  .wrapper {
    max-height: initial;
    min-height: initial;
    display: flex;
    flex-direction: column;
  }

  .description {
    padding: 4rem;
  }

  .background {
    position: relative;
  }
}

@media screen and (max-width: 500px) {
  .title {
    padding: 6rem 2rem;
    font-size: 5rem;
  }
}

.wrapper_subtitle {
  display: flex;
}

.item {
  width: 22rem;
  height: 13rem;
  background-color: $opacityBlue;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: 0.25s ease;
  cursor: pointer;

  &:hover {
    background-color: $blue;
  }
}

.link {
  font-family: "Univers LT Std";
  font-size: 1.7rem;
  font-weight: 400;
  letter-spacing: 1.1px;
  color: white;
  display: flex;
  justify-content: space-between;
  margin-right: 2.5rem;
}

.arrow {
  border: solid white;
  border-width: 0 2px 2px 0;
  display: inline-block;
  padding: 3px;
  height: 0px;
  transform: rotate(-45deg);
}

@media screen and (max-width: 1150px) {
  .wrapper {
    flex-direction: column;
  }
  .item {
    height: 8rem;
  }
  .link {
    width: 9.4rem;
  }
}

@media screen and (max-width: 600px) {
  .item {
    height: 6rem;
  }
}

.biggerMarginTop {
  top: 58% !important;
}
.middleMarginTop {
  top: 55% !important;
}

.macbookTop {
  top: 50% !important;
}
